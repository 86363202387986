/* eslint react/jsx-pascal-case: "off" */

import React, { useState } from 'react'

import Marquee from "react-fast-marquee";

import Layout from "../components/layout"
import Seo from "../components/seo"

import Logo from "../assets/logo.svg";
import SideTick from "../assets/side-tick.svg";
import Carousel1_1 from "../assets/carousel1_1.svg";
import Carousel1_2 from "../assets/carousel1_2.svg";
import Carousel1_3 from "../assets/carousel1_3.svg";

import ArrowUpRight from "../assets/arrow-up-right.svg";

const pressBuy = () => {
  window.location.href = 'https://cometeer.com/partygrounds';
};

const pressTeam = () => {
  window.location.href = 'https://cometeer.com/office';
};

const IndexPage = () => {

  
  const saleState = false;
  
  const [isActive, setActive] = useState(false);
  
  const openPanel = () => {
    setActive(!isActive);
  };

  return (
  <Layout>
    <Seo />
    <div className="container-fluid p-0 m-0 h-100">
      <div className="row justify-content-md-center g-0 h-100 ">
        <div className="d-none d-lg-flex col-lg-1 border border-start-0 d-flex justify-content-center align-items-center ">
          <SideTick
              width={40}
              height={420}
              alt="Design Element Tick Lines"
              className="img-fluid"
          />           
        </div>
        <div className="col-1 col-lg-3 border border-start-0 border-end-0 align-middle">
        </div>
        <div className="col-10 col-lg-4 border d-flex flex-column align-items-center justify-content-end">
            <Logo 
              width={737}
              height={173}
              alt="Partygrounds Logo"
              className="img-fluid position-absolute top-0 start-50 translate-middle logo"
            />
          <h3 className=" position-absolute top-0 start-50 translate-middle tagline">Coffee brewed for founders</h3>
          <div id="carouselMiddle" className="carousel slide mb-3  d-lg-none" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="container mx-5">
                    <div className="row ">
                      <div className="col-3 col-lg-3">
                        <Carousel1_1
                          width={32.12}
                          height={55.99}
                          alt="Coffee with heat coming off it"
                          className="img"
                        />  
                      </div>
                      <div className="col-6 col-lg-5 ">
                        <h6 className="text-start">HOT COFFEE</h6>
                        <p className="text-start">Combine frozen puck with 6-8 oz of hot water.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="container mx-5">
                    <div className="row justify-content-md-center ">
                      <div className="col-3 col-lg-3">
                        <Carousel1_2
                          width={50.18}
                          height={51.31}                        
                          alt="Pour capsule into glass with icecubes"
                          className="img"
                        /> 
                      </div>
                      <div className="col-6 col-lg-5">
                        <h6 className="text-start">ICED COFFEE</h6>
                        <p className="text-start">Melt contents and pour into 6-8 oz of hot water.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="container mx-5">
                    <div className="row justify-content-md-center ">
                      <div className="col-3 col-lg-3">
                        <Carousel1_3
                          width={53.49}
                          height={54.11}                       
                          alt="Pour capsule into mug with heat coming off it"
                          className="img"
                        /> 
                      </div>
                      <div className="col-6 col-lg-5">
                        <h6 className="text-start">HOT LATTE</h6>
                        <p className="text-start">Melt contents and then add 6 oz of steamed milked.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselMiddle" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselMiddle" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            {!saleState && (
              <>
                <div className={"text-danger missed-cta d-flex justify-content-between align-items-center " + (isActive ? "open" : null)}>
                  <div onClick={() => pressTeam()}>cometeer can still hook up your entire team with delicious coffee</div>
                  <ArrowUpRight
                    width={48}
                    height={48}
                  />
                </div>
                <div className="marquee-clickable mb-3 d-lg-none" onClick={() => openPanel()}>
                  <Marquee gradient={false}>
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p>
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                  </Marquee>
                </div>
              </>
            )}
            {saleState && (
              <button onClick={() => pressBuy()} type="button" className="btn btn-primary btn-lg buy mb-4 d-lg-none">BUY</button>
            )}         
        </div>
        <div className="col-1 col-lg-3 border border-start-0 border-end-0">
        </div>
        <div className="col col-lg-1 border border-end-0 d-flex justify-content-center align-items-center d-none d-lg-flex">
          <SideTick
                width={40}
                height={420}
                alt="Design Element Tick Lines"
                className="img-fluid"
            /> 
        </div>
      </div>
    </div>
  </Layout>
  );
}

export default IndexPage
